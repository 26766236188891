import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const PorqueAlquilar = ({ s }) => {
	const intl = useIntl();

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="why-rent">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.porquealquilar?.title)} />
					<main className="page">
						<article className="bg-img">
							<h1 className="white-text prequel-font">{Tools.convertString(s.porquealquilar?.header_title)}</h1>
							<picture>
								<source srcSet="/img/bg/why-rent-desktop.jpg" media="(min-width: 768px)" />
								<img srcSet="/img/bg/why-rent.jpg" alt={``} />
							</picture>
						</article>

						<article className="box text">
							<div className="wrapper-text">
								<ul className="breadcrumbs white-bg">
									<li className="link">
										<Link to="/" className="ltblue-text-02">
											Home
										</Link>
									</li>
									<li className="active">
										<p className="lightgray-text-07">{Tools.convertString(s.porquealquilar?.header_title)}</p>
									</li>
								</ul>
								<p className="box">{Tools.convertString(s.porquealquilar?.text)}</p>
							</div>
						</article>

						<article className="box list">
							<ul>
								<li>
									<div className="wrapper">
										<span className="black-bg-01 white-text saint-font">1</span>
										<h4 className="black-text-01 prequel-font">{Tools.convertString(s.porquealquilar?.item1)}</h4>
									</div>
								</li>
								<li>
									<div className="wrapper">
										<span className="black-bg-01 white-text saint-font">2</span>
										<h4 className="black-text-01 prequel-font">{Tools.convertString(s.porquealquilar?.item2)}</h4>
									</div>
								</li>
								<li>
									<div className="wrapper">
										<span className="black-bg-01 white-text saint-font">3</span>
										<h4 className="black-text-01 prequel-font">{Tools.convertString(s.porquealquilar?.item3)}</h4>
									</div>
								</li>
								<li>
									<div className="wrapper">
										<span className="black-bg-01 white-text saint-font">4</span>
										<h4 className="black-text-01 prequel-font">{Tools.convertString(s.porquealquilar?.item4)}</h4>
									</div>
								</li>
								<li>
									<div className="wrapper">
										<span className="black-bg-01 white-text saint-font">5</span>
										<h4 className="black-text-01 prequel-font">{Tools.convertString(s.porquealquilar?.item5)}</h4>
									</div>
								</li>
							</ul>
						</article>

						<article className="box last-text">
							<p>
								<b>{Tools.convertString(s.porquealquilar?.end_text1)}</b> {Tools.convertString(s.porquealquilar?.end_text2)}
							</p>
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(PorqueAlquilar);
